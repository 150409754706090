import { FC, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRecoilState } from "recoil";

import {
    AppForm,
    AppFormContent,
    AppFormModal,
    AppFormSelect,
} from "../../../components";
import { useTranslation } from "../../../hooks";
import { atomActiveClient } from "../../../atoms";
import {
    TKey,
    errorToast,
    getUserFullName,
    successToast,
} from "../../../utils";
import { schema } from "./schema";
import { UserApi } from "../../../apis";
import { OptionType, PrimitiveObject, User } from "../../../models";

interface UserInviteForm {
    assignUserId: number;
}

interface UserDeleteModalProps {
    showForm: boolean;
    onHide: () => void;
    deleteUserId: number;
}

export const UserDeleteModal: FC<UserDeleteModalProps> = ({
    showForm,
    onHide,
    deleteUserId,
}) => {
    // hooks
    const { t } = useTranslation();

    // atoms
    const [activeClient] = useRecoilState(atomActiveClient);

    // state
    const [loading, setLoading] = useState(false);
    const [userOptions, setUserOptions] = useState<OptionType[]>([]);
    // const [, setSelectedUser] = useState<string>();

    // form
    const methods = useForm({
        resolver: yupResolver(schema(t)),
    });
    const { handleSubmit, formState, reset } = methods;

    const onSubmitHandler = (formData: UserInviteForm) => {
        setLoading(true);
        UserApi.deleteUser<PrimitiveObject, PrimitiveObject>({
            assignUserId: formData.assignUserId,
            deleteUserId: deleteUserId,
        })
            .then(({ errorMessage, response }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    successToast(t("admin.user.list:delete.toast.success"));
                    onHide();
                    reset();
                }
            })
            .finally(() => setLoading(false));
    };

    const fetchUsers = () => {
        if (!activeClient || !deleteUserId) {
            return;
        }

        setLoading(true);

        UserApi.getForClient<User>(1, {
            pagination: false,
        })
            .then(({ response, errorMessage }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response !== null) {
                    setUserOptions(
                        response.items
                            .filter((x) => x.id !== deleteUserId)
                            .map((item) => ({
                                label: getUserFullName(item) || item.email,
                                value: item.id,
                            })),
                    );
                }
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        fetchUsers();
    }, [deleteUserId]);

    const renderForm = () => (
        <FormProvider {...methods}>
            <h4 className="pt-1 pb-3">
                {t("admin.user.list:delete.modal.description")}
            </h4>
            <AppForm
                onSubmit={(e) => e.preventDefault()}
                style={{ minHeight: "300px" }}
            >
                <AppFormContent>
                    <AppFormSelect
                        id="assignUserId"
                        name="assignUserId"
                        label={t("admin.user.form:label.assignUserId")}
                        placeholder={t(
                            "admin.user.form:placeholder.assignUserId",
                        )}
                        required={true}
                        block
                        options={userOptions}
                        inputClassName="flex-grow-1"
                    />
                </AppFormContent>
            </AppForm>
        </FormProvider>
    );

    if (!showForm) {
        return <></>;
    }

    return (
        <AppFormModal
            show={showForm}
            icon="mail"
            title="admin.user.list:delete.modal.title"
            onSubmit={handleSubmit(onSubmitHandler)}
            onHide={() => {
                onHide();
                reset();
            }}
            isLoading={formState.isSubmitting || loading}
            disabled={!activeClient}
            createLabel={TKey.Common.Button.Delete}
            size="lg"
        >
            {renderForm()}
        </AppFormModal>
    );
};
