import * as yup from "yup";

import { TKey } from "../../../utils";

export const schema = (t: (val: string) => string) =>
    yup.object().shape({
        assignUserId: yup
            .number()
            .required(t(TKey.Common.Message.Validation.RequiredField)),
    });
