import { Canceler } from "axios";
import { FinalResponse, ListResponse, ROUTES, route } from "../config";
import { EntityAPI } from "./core";

const {
    api_users_get_collection: API_GET_COLLECTION,
    api_users_post_collection: API_POST_COLLECTION,
    api_users_get_item: API_GET_ITEM,
    api_users_patch_item: API_PATCH_ITEM,
    api_users_delete_item: API_DELETE_ITEM,
    api_users_get_for_client_collection: API_GET_FOR_CLIENT_COLLECTION,
    api_users_get_for_responsible_collection:
        API_GET_FOR_RESPONSIBLE_COLLECTION,
    api_users_get_for_workspace_collection: API_GET_FOR_WORKSPACE_COLLECTION,
    api_users_patch_profile_item: API_UPDATE_PROFILE,
    api_users_patch_change_password_item: API_CHANGE_PASSWORD,
    api_users_exist_collection: API_EXIST_ITEM,
    api_users_attach_collection: API_ATTACH_ITEM,
    api_users_invite_collection: API_INVITE_ITEM,
    api_users_delete_collection: API_POST_USER_DELETE_COLLECTION,
} = ROUTES;

export abstract class UserApi extends EntityAPI {
    protected static GET_COLLECTION = API_GET_COLLECTION;

    protected static POST_COLLECTION = API_POST_COLLECTION;

    protected static GET_ITEM = API_GET_ITEM;

    // protected static PUT_ITEM = API_PUT_ITEM;

    protected static PATCH_ITEM = API_PATCH_ITEM;

    protected static DELETE_ITEM = API_DELETE_ITEM;

    public static async getForClient<R>(
        page = 1,
        extraParams = {},
        cancelToken?: (c: Canceler) => void,
    ): Promise<FinalResponse<ListResponse<R> | null>> {
        const source = this.createCancelTokenSource();

        if (cancelToken) {
            cancelToken(source.cancel);
        }

        return this.makeGet<R>(
            API_GET_FOR_CLIENT_COLLECTION,
            {
                ...extraParams,
                page,
            },
            {
                cancelToken: source.token,
            },
        )
            .then(({ data }) => this.handleListResponse<R>(data))
            .catch((e) => this.handleAllError(e));
    }

    public static async getForResponsible<R>(
        page = 1,
        extraParams = {},
        cancelToken?: (c: Canceler) => void,
    ): Promise<FinalResponse<ListResponse<R> | null>> {
        const source = this.createCancelTokenSource();

        if (cancelToken) {
            cancelToken(source.cancel);
        }

        return this.makeGet<R>(
            API_GET_FOR_RESPONSIBLE_COLLECTION,
            {
                ...extraParams,
                page,
            },
            {
                cancelToken: source.token,
            },
        )
            .then(({ data }) => this.handleListResponse<R>(data))
            .catch((e) => this.handleAllError(e));
    }

    public static async getForWorkspace<R>(
        page = 1,
        extraParams = {},
        cancelToken?: (c: Canceler) => void,
    ): Promise<FinalResponse<ListResponse<R> | null>> {
        const source = this.createCancelTokenSource();

        if (cancelToken) {
            cancelToken(source.cancel);
        }

        return this.makeGet<R>(
            API_GET_FOR_WORKSPACE_COLLECTION,
            {
                ...extraParams,
                page,
            },
            {
                cancelToken: source.token,
            },
        )
            .then(({ data }) => this.handleListResponse<R>(data))
            .catch((e) => this.handleAllError(e));
    }

    public static async updateProfile<R, P>(
        id: number,
        payload: P,
    ): Promise<FinalResponse<R | null>> {
        return this.makePatch<R, P>(
            route(API_UPDATE_PROFILE, { id }),
            JSON.stringify(payload),
        )
            .then(({ data }) => this.handleResponse<R>(data))
            .catch((e) => this.handleAllError(e));
    }

    public static async changePassword<R, P>(
        id: number,
        payload: P,
    ): Promise<FinalResponse<R | null>> {
        return this.makePatch<R, P>(
            route(API_CHANGE_PASSWORD, { id }),
            JSON.stringify(payload),
        )
            .then(({ data }) => this.handleResponse<R>(data))
            .catch((e) => this.handleAllError(e));
    }

    public static async exist<R>(
        extraParams = {},
        cancelToken?: (c: Canceler) => void,
    ): Promise<FinalResponse<ListResponse<R> | null>> {
        const source = this.createCancelTokenSource();

        if (cancelToken) {
            cancelToken(source.cancel);
        }

        return this.makeGet<R>(
            API_EXIST_ITEM,
            {
                ...extraParams,
            },
            {
                cancelToken: source.token,
            },
        )
            .then(({ data }) => this.handleListResponse<R>(data))
            .catch((e) => this.handleAllError(e));
    }

    public static async attach<R, P = null>(
        payload: P,
    ): Promise<FinalResponse<R | null>> {
        return this.makePost<R, P>(API_ATTACH_ITEM, payload)
            .then(({ data }) => this.handleResponse<R>(data))
            .catch((e) => this.handleAllError(e));
    }

    public static async invite<R, P = null>(
        payload: P,
    ): Promise<FinalResponse<R | null>> {
        return this.makePost<R, P>(API_INVITE_ITEM, payload)
            .then(({ data }) => this.handleResponse<R>(data))
            .catch((e) => this.handleAllError(e));
    }

    public static async deleteUser<R, P = null>(
        payload: P,
    ): Promise<FinalResponse<R | null>> {
        return this.makePost<R, P>(API_POST_USER_DELETE_COLLECTION, payload)
            .then(({ data }) => this.handleResponse<R>(data))
            .catch((e) => this.handleAllError(e));
    }
}
